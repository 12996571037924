import React from "react"
import {graphql, Link} from "gatsby"
import BlogOverview from "../components/blog/blog-overview";

export const query = graphql`
  fragment flutterBlogPostQuery on PrismicFlutterPost {
    id
    uid
    first_publication_date
    last_publication_date
    data {
      meta_title {
        text
      }
      meta_description {
        text
      }
      title {
        text
      }
      rich_text {
        text
      }
      author_name {
        text
      }
      tag
      hero_image {
        alt
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      featured
    }
  }
{
  featured: allPrismicFlutterPost(
    limit: 2
    sort: {order: DESC, fields: first_publication_date}
    filter: {data: {featured: {eq: true}, tag: {in: ["flutter", null]}}}
  ) {
    totalCount
    nodes {
      ...flutterBlogPostQuery
    }
  }
  normal: allPrismicFlutterPost(
    limit: 20
    sort: {order: DESC, fields: first_publication_date}
    filter: {data: {featured: {ne: true}, tag: {in: ["flutter", null]}}}
  ) {
    totalCount
    nodes {
      ...flutterBlogPostQuery
    }
  }
}
`

const FlutterBlogOverview = ({data}) => {
  return BlogOverview(data, {
    title: "The Flutter Subscriptions Blog",
    description: "Everything you need to know to integrate Subscriptions into your Flutter App",
    url: "https://www.linkfive.io/flutter-blog/"
  })
}

export default FlutterBlogOverview
